import React, { FormEvent } from "react"
import { returnDenumireLunaByValue } from "../../utils"
import {
  getMyConvetieConsum,
  putConventieConsum,
} from "../../utils/api/userInfo"
import { NUMERIC_REGEX } from "../../utils/regex"
import Button from "../Button"
import FormInput from "../FormInput"
import InfoMsg, { InfoMsgType } from "../InfoMsg"
import "./styles.css"
import { Link } from "gatsby"
import { getUserInfo, saveUserInfo } from "../../utils/services/keepAuth"
import InfoLoading from "../InfoLoading"

const consumDefault = [205, 165, 135, 70, 50, 0, 0, 0, 35, 70, 105, 175]

const CONSUM_ERROR = "Consumul trebuie să fie un număr întreg între 0 și 1000"

const checkFormData = (programConsum: string[]) => {
  return (
    programConsum.length === 12 &&
    programConsum.every(el => checkValoareConsum(el))
  )
}

const checkValoareConsum = (consum: string) => {
  if (NUMERIC_REGEX(consum)) {
    const intValue = parseInt(consum)
    return intValue >= 0 && intValue <= 1000
  } else return false
}

const checkFormDataModified = (
  consumInitial: number[] | null,
  consumFormular: string[]
) => {
  if (consumInitial === null) return true
  return !consumInitial.every((el, i) => el === parseInt(consumFormular[i]))
}

type PropTypes = {
  dismissPopup?: () => void
}
export default function FormularConventieConsum({ dismissPopup }: PropTypes) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: true,
  })

  const [doesExist, setDoesExist] = React.useState<boolean>(false)

  const [consumFormular, setConsumFormular] = React.useState<string[]>([])

  const consumInitial = React.useRef<number[] | null>(null)

  React.useEffect(() => {
    const fetchConventieConsum = async () => {
      try {
        const apiResponse = await getMyConvetieConsum()
        console.log(apiResponse)
        if (apiResponse.type) {
          setConsumFormular(apiResponse.data.conventie.map(el => el + ""))
          setDoesExist(apiResponse.data.doesExist)
          consumInitial.current = apiResponse.data.conventie
        } else {
          setDoesExist(false)
          setConsumFormular(consumDefault.map(el => el + ""))
          consumInitial.current = consumDefault
        }
      } catch (error) {
        console.error(error)
      } finally {
        setInfoMsg({ isLoading: false })
      }
    }

    fetchConventieConsum()
  }, [])

  // React.useEffect(() => {
  //   var dashContainer = document.getElementsByClassName("dashContainer")
  //   console.log(dashContainer[0])
  //   if (showPopup) dashContainer[0].classList.add("blurred")
  //   else dashContainer[0].classList.remove("blurred")
  // }, [showPopup])

  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    const intValue = parseInt(value)
    setConsumFormular(oldFormData => {
      const index = parseInt(name)
      if (index >= 0 && index < 12) {
        oldFormData[index] = value
      }
      return [...oldFormData]
    })
  }

  const submitForm = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setInfoMsg({ isLoading: true, msg: "" })
      const apiResponse = await putConventieConsum(
        consumFormular.map(el => parseInt(el))
      )
      if (apiResponse.type) {
        setInfoMsg({
          isLoading: false,
          status: "ok",
          msg: checkFormDataModified(consumInitial.current, consumFormular)
            ? "Programul tău de consum a fost modificat"
            : "Programul tău de consum a fost aprobat",
        })
        saveUserInfo({
          ...getUserInfo(),
          needsProgramConsum: false,
        })
        if (dismissPopup != null) setTimeout(dismissPopup, 4000)
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare la modificarea datelor. Vă rugăm să încercați din nou.",
        })
    } catch (error) {
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare la confirmare datelor. Vă rugăm să încercați din nou.",
      })
      console.error(error)
    }
  }

  // console.log(consumInitial.current, consumFormular)
  // console.log(checkFormDataModified(consumInitial.current, consumFormular))
  if (infoMsg.isLoading) return <InfoMsg infoMsg={infoMsg} withSpinner />
  else
    return (
      <form
        id="conventieConsumForm"
        className="newStyleForm"
        onSubmit={submitForm}
      >
        {consumFormular.map((el, i) => (
          <FormInput
            key={i}
            containerClassName="newStyleInput"
            type="number"
            label={returnDenumireLunaByValue(i)}
            isRequired={true}
            name={i.toString()}
            onChange={handleInputChange}
            value={el}
            errorTest={checkValoareConsum}
            errorMessage={CONSUM_ERROR}
          />
        ))}

        <InfoMsg containerClassName="row" infoMsg={infoMsg} />
        {infoMsg.status !== "ok" && (
          <div className="row rowButton">
            <Button
              isLoading={infoMsg.isLoading}
              as="button"
              type="submit"
              label={
                checkFormDataModified(consumInitial.current, consumFormular) ||
                (dismissPopup == null && doesExist)
                  ? "Salvează modificările"
                  : "Confirmă programul de consum"
              }
              className="newStyleButton"
              aspect="primary"
              disabled={
                !checkFormData(consumFormular) ||
                (dismissPopup == null &&
                  doesExist &&
                  !checkFormDataModified(consumInitial.current, consumFormular))
              }
              // onClick={submitForm}
            />
          </div>
        )}
        <p className="text">
          Dacă nu transmiți autocitirea și nici nu se realizează citirea
          contorului, factura va fi emisa conform programului de consum
          stipulate mai sus.
        </p>
        {dismissPopup != null && (
          <p className="text">
            Programul de consum poate fi modifica oricând din sectiune{" "}
            <Link to="/app/programConsum">program de consum </Link>
            situată în meniul contului tău
          </p>
        )}
      </form>
    )
}
