import { RouteComponentProps } from "@reach/router"
import React from "react"
import { InfoMsgType } from "../../InfoMsg"
import TemplatePage from "../../TemplatePage"
import FormularConventieConsum from "../../conventieConsum/form"
import "./style.css"

export default function ProgramConsumPage({
  location,
  navigate,
}: RouteComponentProps) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })

  // console.log(userInfo)
  //FIXME: Double scrollable page on mobile
  return (
    <>
      <TemplatePage
        // additionalClassName="contactPage"
        showDash
        location={location!}
        title="Program de consum"
        showMobileMenu={false}
        additionalTitleClassName="bgGrayOnTablet"
      >
        <div className="programConsumPage">
          <FormularConventieConsum />
        </div>
      </TemplatePage>
    </>
  )
}
