import React, { useEffect, useState } from "react"
import { colors, generalStyles } from "../../../styles/globals"
import { dateToDesiredFormat } from "../../../utils"
import { getFacturi, getIncasari } from "../../../utils/api/sivap"
import { getContracts, getUserInfo } from "../../../utils/services/keepAuth"
import Spinner from "../../Spinner"
import TemplatePage from "../../TemplatePage"
import "./style.css"
import InfoLoading from "../../InfoLoading"
// .toLocaleString("ro-RO", {
// minimumFractionDigits: 2,
// maximumFractionDigits: 2,
// })}

const valDeAdaugat = item => (item.tipDoc === "F" ? item.debit : -item.credit)

const combinaFacturiCuChitante = (facturi, chitante) => {
  var deReturnat = []
  deReturnat = deReturnat.concat(
    facturi.map(item => ({
      tipDoc: "F",
      nrDoc: item.nr_factura.split("/")[1]
        ? item.nr_factura.split("/")[1]
        : item.nr_factura.split("/")[0],
      dataDoc: new Date(item.data_fact),
      debit: Math.round(item.total_factura * 100),
      credit: null,
      sold: 0,
    }))
  )
  deReturnat = deReturnat.concat(
    chitante
      .filter(item => !item.doc_incas.includes("GZM"))
      .map(item => ({
        tipDoc: "C",
        nrDoc: item.doc_incas,
        dataDoc: new Date(item.data_incas),
        debit: null,
        credit: Math.round(item.val_incas * 100),
        sold: 0,
      }))
  )

  deReturnat.sort((a, b) => {
    return a.dataDoc.getTime() - b.dataDoc.getTime()
  })

  // console.log(deReturnat)
  for (var i = 0; i < deReturnat.length; i++) {
    if (i === 0) deReturnat[i].sold = valDeAdaugat(deReturnat[i])
    else {
      deReturnat[i].sold = valDeAdaugat(deReturnat[i]) + deReturnat[i - 1].sold
      // deReturnat[i].sold = parseFloat(
      //   deReturnat[i].sold.toLocaleString("en-GB", {
      //     minimumFractionDigits: 2,
      //     maximumFractionDigits: 2,
      //   })
      // )
    }
  }

  return deReturnat
}
export default function FisaClientPage({ location }) {
  const [items, setItems] = useState(null)
  const [infoMsg, setInfoMsg] = useState(null)
  const [userInfo, setUserInfo] = useState(null)

  const getDate = async () => {
    setInfoMsg("Vă rugăm așteptați")
    try {
      const apiFacturi = await getFacturi()
      const apiChitante = await getIncasari()
      // console.log(apiFacturi)
      console.log(apiChitante)
      if (apiChitante.type && apiFacturi.type) {
        setItems(
          combinaFacturiCuChitante(apiFacturi.data, apiChitante.data).reverse()
        )
        setInfoMsg(null)
      } else setInfoMsg("Indisponibil momentan")
    } catch (error) {
      console.log(error)
      setInfoMsg("Indisponibil momentan")
    }
  }
  useEffect(() => {
    getDate()
    if (getUserInfo()) setUserInfo(getUserInfo())
  }, [])

  return (
    <TemplatePage
      showDash
      title="Fisă client"
      location={location}
      // topPaneHeight={160}
      // additionalTopPaneElement={
      //   <div
      //     style={{
      //       overflow: "auto",
      //       position: "relative",
      //       width: "100%",
      //       height: 80,
      //       backgroundColor: colors.accent,
      //     }}
      //   >
      //     <div
      //       style={{
      //         height: 24,
      //         width: 200,
      //         margin: "12px auto 0 auto",
      //         textAlign: "center",
      //         backgroundColor: colors.secondGray,
      //         fontSize: 20,
      //         fontWeight: 700,
      //         color: colors.white,
      //       }}
      //     >
      //       {userInfo ? " Cod client:" : ""}
      //     </div>
      //     <div
      //       style={{
      //         height: 30,
      //         width: 200,
      //         margin: "0 auto",
      //         textAlign: "center",
      //         backgroundColor: colors.secondGray,
      //         fontSize: 24,
      //         fontWeight: 700,
      //         color: colors.white,
      //       }}
      //     >
      //       {userInfo ? userInfo.codClient : ""}
      //     </div>
      //   </div>
      // }
    >
      <InfoLoading infoMsg={infoMsg} />
      {items && items.length && (
        <table className="tabelStyle fixedHeader darkTable tableFacturi">
          <thead>
            <tr>
              <th style={{ backgroundColor: "rgb(239, 239, 239)" }}>Număr</th>
              <th style={{ backgroundColor: "rgb(239, 239, 239)" }}>
                Data emiterii
              </th>
              <th style={{ backgroundColor: "rgb(239, 239, 239)" }}>
                Debit (Lei)
              </th>
              <th style={{ backgroundColor: "rgb(239, 239, 239)" }}>
                Credit (Lei)
              </th>
              <th style={{ backgroundColor: "rgb(239, 239, 239)" }}>
                Sold (Lei)
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={item.nrDoc + index}>
                <td>{item.tipDoc + " - " + item.nrDoc}</td>
                <td>{dateToDesiredFormat(item.dataDoc)}</td>
                <td>
                  {item.debit != null
                    ? (item.debit / 100).toLocaleString("ro-RO", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td>
                  {item.credit != null
                    ? (item.credit / 100).toLocaleString("ro-RO", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""}
                </td>
                <td>
                  {(item.sold / 100).toLocaleString("ro-RO", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  {/* {NumberFormatter.format(-0.00000001)} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </TemplatePage>
  )
}

export const NumberFormatter = new Intl.NumberFormat("ro-RO", {
  style: "currency",
  currency: "LEI",
})
