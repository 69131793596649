import React, { useEffect, useRef, useState } from "react"
import UsefullInfo from "../../../components/Dash/UsefullInfo"
import { colors } from "../../../styles/globals"
import { createObjectURL } from "../../../utils"
import { getFacturaPdfByNrFac } from "../../../utils/api/fisierFacturi"
import { getFacturi } from "../../../utils/api/sivap"
import { getContracts } from "../../../utils/services/keepAuth"
import InfoLoading from "../../InfoLoading"
import TemplatePage from "../../TemplatePage"
import TabelRestante from "./TabelRestante"
import VerificareInfoClientPopup from "../../verificareInfoClient"

const infoItem = [
  {
    title: "Centre de plată",
    link: "/infoUtile/centreDePlata",
  },
  {
    title: "Când și cum se facturează?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=61b1b3874db367c6fa97d92e",
  },
  {
    title: "Unde și cum plătesc factura?",
    link: "/infoUtile/faqs/generale?idSelectedFaq=65705b332144364155f83b2f",
  },
]

export default function FacturiPage({
  location,
  setShowPaymentRedirectLoading,
}) {
  const [restante, setRestante] = useState(null)
  const [infoMsg, setInfoMsg] = useState(null)

  const [showMoreInfo, setShowMoreInfo] = useState(true)

  const nrFacReferintaPlata = useRef(null)

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(oldValue => !oldValue)
  }

  const seeFact = async nrFactura => {
    var windowReference = window.open()
    // console.log("TO DO SEE PDF FAC: ", nrFactura.replace("/", ""), nrFactura)
    try {
      // const apiResponse = await getFacturaPdfByNrFac(nrFactura);
      const apiResponse = await getFacturaPdfByNrFac(nrFactura.replace("/", ""))
      if (apiResponse.type) {
        var url = createObjectURL(apiResponse)
        // window.open(url, "_blank").focus();
        windowReference.location = url
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const getFacturiPtClc = async () => {
      setInfoMsg("Vă rugăm așteptați")
      try {
        const response = await getFacturi()
        if (response.type) {
          const [restante, achitate] = splitFacturi(response.data)

          if (restante.length > 0)
            nrFacReferintaPlata.current = {
              nrFac: restante[restante.length - 1].nr_factura,
              idDocei: restante[restante.length - 1].id_factura,
            }
          else
            nrFacReferintaPlata.current = {
              nrFac: achitate[0].nr_factura,
              idDocei: achitate[0].id_factura,
            }

          setInfoMsg(null)
          if (restante.length >= 0) setRestante(restante)
          if (restante.length > 5) setShowMoreInfo(false)
        } else setInfoMsg("Indisponibil")
      } catch (error) {
        console.log(error)
        setInfoMsg("Indisponibil")
      }
    }
    getFacturiPtClc()
  }, [0])

  return (
    <TemplatePage title="Facturi restante" location={location} showDash>
      <InfoLoading infoMsg={infoMsg} />
      <TabelRestante
        facturi={restante}
        seeFact={seeFact}
        setShowPaymentRedirectLoading={setShowPaymentRedirectLoading}
        nrFacReferintaPlata={nrFacReferintaPlata.current}
      />
      <UsefullInfo
        title="Informații despre facturi:"
        items={infoItem}
        expanded={true}
        toggleShowMoreInfo={toggleShowMoreInfo}
      />
    </TemplatePage>
  )
}

export const splitFacturi = facturi => {
  const restante = []
  const achitate = []
  facturi.sort((a, b) => {
    return new Date(b.data_fact).getTime() - new Date(a.data_fact).getTime()
  })
  for (var i = 0; i < facturi.length; i++) {
    if (facturi[i].rest_plata > 0) restante.push(facturi[i])
    else achitate.push(facturi[i])
  }
  // // console.log(restante);
  // restante.sort((a, b) => {
  //   new Date(b) - new Date(a)
  // })
  // // console.log(restante);
  // console.log(achitate)
  // achitate.sort((a, b) => {
  //   return new Date(b.data_fact) - new Date(a.data_fact)
  // })
  // console.log(achitate)
  return [restante, achitate]
}
