import { apiBase, customGet } from "./utils"

const baseUrl = `${apiBase}/api/sivap`

export function getSoldClient() {
  return customGet(`${baseUrl}/sold`)
}

export function getUltimaPlata() {
  return customGet(`${baseUrl}/ultimaPlata`)
}

export function getUltimulIndex() {
  return customGet(`${baseUrl}/ultimulIndex`)
}

export function getFacturi() {
  return customGet(`${baseUrl}/facturi`)
}

export function getIncasari() {
  return customGet(`${baseUrl}/incasari`)
}

export function getConsum(idContract) {
  return customGet(`${baseUrl}/consum`)
}
